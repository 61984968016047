import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Image from '../../assets/images/home/meeting.jpg';

const MeetingConfirmed = () => {
    const [pixelLoaded, setPixelLoaded] = useState(false);
    const [tikTokPixelLoaded, setTikTokPixelLoaded] = useState(false);
    const location = useLocation();

    // ✅ Extract query parameters
    const params = new URLSearchParams(location.search);
    const inviteeEmail = params.get("invitee_email");
    const eventType = params.get("event_type_name");
    const eventStart = params.get("event_start_time");
    const eventId = params.get("invitee_uuid");

    // ✅ Check if all required parameters are present
    const isMeetingConfirmed = inviteeEmail && eventType && eventStart;

    useEffect(() => {
        const checkMetaPixel = () => {
            if (typeof window.fbq === "function") {
                setPixelLoaded(true);
                console.log("✅ Meta Pixel is loaded from HubSpot.");
            }
        };

        const checkTikTokPixel = () => {
            if (typeof window.ttq === "object" && typeof window.ttq.track === "function") {
                setTikTokPixelLoaded(true);
                console.log("✅ TikTok Pixel is loaded.");
            }
        };

        // Initial checks
        checkMetaPixel();
        checkTikTokPixel();

        // Detect script injection by HubSpot or TikTok
        const observer = new MutationObserver(() => {
            checkMetaPixel();
            checkTikTokPixel();
        });

        observer.observe(document.documentElement, { childList: true, subtree: true });

        return () => observer.disconnect(); // Clean up
    }, []);

    useEffect(() => {
        if (!pixelLoaded || !isMeetingConfirmed) return; // Only proceed if Meta Pixel is loaded & data is valid

        // ✅ Track Meta Pixel event
        window.fbq("track", "CalendlyMeetingScheduled", {
            invitee_email: inviteeEmail,
            event_type: eventType,
            event_start: eventStart,
        });

        console.log("🎯 Meta Pixel event tracked with:", {
            invitee_email: inviteeEmail,
            event_type: eventType,
            event_start: eventStart,
        });

    }, [pixelLoaded, isMeetingConfirmed, eventStart, eventType, inviteeEmail]);

    useEffect(() => {
        if (!tikTokPixelLoaded || !isMeetingConfirmed) return; // Only proceed if TikTok Pixel is loaded & data is valid

        // ✅ Track TikTok Pixel event
        window.ttq.track("ViewContent", {
            event_name: "Meeting Confirmed Page View",
            content_name: "Meeting Confirmation",
            content_category: "Appointment",
            invitee_email: inviteeEmail,
            event_type: eventType,
            event_start: eventStart,
	    content_id: eventId
        });

        console.log("🎯 TikTok Pixel event tracked with:", {
            invitee_email: inviteeEmail,
            event_type: eventType,
            event_start: eventStart,
        });

    }, [tikTokPixelLoaded, isMeetingConfirmed, eventStart, eventType, inviteeEmail, eventId]);

    return (
        <section>
            <div className="container mx-auto">
                <div className="flex justify-center items-center" style={{ height: '100dvh' }}>
                    <div className="flex justify-center items-center rounded-lg w-full" style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + Image + ")", backgroundSize: "cover", height: '80dvh' }}>
                        {isMeetingConfirmed ? (
                            <h2 className="text-white text-2xl">Meeting Confirmed! 🎉</h2>
                        ) : (
                            <h2 className="text-white text-4xl">Meeting Not Confirmed</h2>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MeetingConfirmed;
