import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import hero from '../../assets/images/contact/hero.jpg';
import './Contact.style.scss';
import TokenService from "../../API/token.service";
import { ApiUrl } from '../../API/Api.config';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


const CaseStudy = () => {

    document.title = "Contact | PROMOTE"

    const { register, handleSubmit, formState: { error } } = useForm();
    const token = TokenService.getLocalAccessToken()
    const MySwal = withReactContent(Swal)

    const useTikTokPixel = () => {
        const [pixelLoaded, setPixelLoaded] = useState(false);

        useEffect(() => {
            const checkPixel = () => {
                if (typeof window.ttq === "object" && typeof window.ttq.track === "function") {
                    setPixelLoaded(true);
                    console.log("✅ TikTok Pixel is loaded.");
                }
            };

            // Check if TikTok Pixel is already available
            checkPixel();

            // Detect script injection dynamically (useful for HubSpot integrations)
            const observer = new MutationObserver(() => checkPixel());
            observer.observe(document.documentElement, { childList: true, subtree: true });

            return () => observer.disconnect(); // Cleanup on unmount
        }, []);

        return pixelLoaded;
    };

    const useMetaPixel = () => {
        const [pixelLoaded, setPixelLoaded] = useState(false);

        useEffect(() => {
            const checkPixel = () => {
                if (typeof window.fbq === "function") {
                    setPixelLoaded(true);
                    console.log("✅ Meta Pixel is loaded from HubSpot.");
                    // window.fbq("track", "PageView"); // Track page view once Pixel is ready
                }
            };

            // Initial check
            checkPixel();

            // Detect script injection by HubSpot
            const observer = new MutationObserver(() => checkPixel());
            observer.observe(document.documentElement, { childList: true, subtree: true });

            return () => observer.disconnect(); // Clean up
        }, []);

        return pixelLoaded;
    };

    const pixelLoaded = useMetaPixel();
    const tikTokPixelLoaded = useTikTokPixel(); // TikTok Pixel Hook

    const handleClick = () => {
        if (pixelLoaded) {
            window.fbq("track", "Submit Contact", { button_name: "Contact" });
            console.log("🎯 Meta Pixel event triggered!");
        } else {
            console.warn("⚠️ Meta Pixel is not available yet.");
        }

        if (tikTokPixelLoaded) {
            window.ttq.track("SubmitForm", {
                event_name: "Contact Form Submission",
                content_name: "Contact Us Form",
                content_category: "Lead Generation",
            });
            console.log("🎯 TikTok Pixel event triggered: SubmitForm!");
        }
    };

    async function sendMessage(data) {
        try {
            await axios.post(ApiUrl + 'contact_us/', data, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Token ' + token } })
                .then(response => {
                    if (response.status === 201) {

                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'success',
                            title: 'Your data has been submitted',
                            showConfirmButton: false,
                            timer: 3000,
                        })

                        const currentUrl = window.location.href;
                        if (!currentUrl.includes('?thank-you')) {
                            window.history.pushState({}, '', currentUrl + (currentUrl.includes('?') ? '&' : '?') + 'thank-you');
                        }

                        handleClick()
                    }
                })
                .catch(error => {
                    if (error.request.status === 400) {
                        MySwal.fire({
                            position: 'top',
                            toast: true,
                            icon: 'error',
                            title: 'Something went wrong',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                    }
                })
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <Header></Header>
            <div id='Contact'>
                <section id='section1' className='section'>
                    <div className='container mx-auto h-full'>
                        <div className='flex flex-col h-full w-full'>
                            <div className="block bg-white rounded-lg">
                                <div className='grid sm:grid-cols-5'>
                                    <div className='col-span-2'>
                                        <h5 className="text-4xl tracking-tight font-extrabold text-left text-primary">Contact us</h5>
                                        <p className='mt-5'>Have any questions or need further information? Feel free to reach out to us. We're here to help and look forward to hearing from you.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='section2' className='section'>
                    <div className='container mx-auto w-full'>
                        <div className='flex flex-col justify-center items-center h-full w-full'>
                            <div className="mx-auto w-full">
                                <form onSubmit={handleSubmit(sendMessage)} className="space-y-8 w-full">
                                    <div>
                                        <label for="email" className="block mb-2 text-sm font-medium text-black">Your email</label>
                                        <input type="email" id="email" {...register('sender')} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5" placeholder="name@yourwebsite.com" required />
                                    </div>
                                    <div>
                                        <label for="subject" className="block mb-2 text-sm font-medium text-black">Subject</label>
                                        <input type="text" id="subject" {...register('subject')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary focus:border-primary" placeholder="Let us know how we can help you" required />
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label for="message" className="block mb-2 text-sm font-medium text-black">Your message</label>
                                        <textarea id="message" rows="6" {...register('message')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary focus:border-primary" placeholder="Leave a comment..."></textarea>
                                    </div>
                                    <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary sm:w-fit hover:bg-secondary focus:ring-4">Send message</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer></Footer>
        </>
    );
}

export default CaseStudy;